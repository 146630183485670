<!--sidebar-->
<c-sidebar
  #sidebar1="cSidebar"
  class="d-print-none sidebar sidebar-fixed bg-dark-gradient border-end"
  colorScheme="dark"
  id="sidebar1"
  visible
>
  <c-sidebar-header class="border-bottom">
    <c-sidebar-brand cSidebarToggle="sidebar1" toggle="unfoldable" style="cursor: pointer;">
      <svg [class]="{icon: false}" cIcon class="sidebar-brand-full" height="32" name="logo" title="CoreUI Logo"></svg>
      <svg cIcon class="sidebar-brand-narrow" height="32" name="signet" title="CoreUI Logo"></svg>
    </c-sidebar-brand>
    <button cSidebarToggle="sidebar1"
            cButtonClose
            class="d-lg-none"
            dark
            toggle="visible"
            aria-label="Toggle sidebar"
    >
    </button>
    <button cSidebarToggler="sidebar1" toggle="unfoldable" aria-label="Toggle sidebar fold"></button>
  </c-sidebar-header>

  <ng-scrollbar #scrollbar="ngScrollbar" (updated)="onScrollbarUpdate(scrollbar.state)" class="overflow" pointerEventsMethod="scrollbar" visibility="hover">
    <c-sidebar-nav #overflow [navItems]="navItems" dropdownMode="close" compact />
  </ng-scrollbar>
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100">
  <!--app-header-->
  <app-default-header
    [cShadowOnScroll]="'sm'"
    class="mb-4 d-print-none header header-sticky p-0 shadow-sm"
    position="sticky"
    sidebarId="sidebar1"
  />
  <!--app-body-->
  <div class="body flex-grow-1">
    <c-container breakpoint="lg" class="h-auto px-4">
      <app-default-breadcrumb />
      <router-outlet />
    </c-container>
  </div>
  <!--app footer-->
  <app-default-footer />
</div>

<!--aside-->
<app-default-aside style="display: none;" />
